
import React from 'react'
import { PhotoGallery } from "../components/PhotoGallery"

// const photos = [
//     "https://i.ibb.co/vX339kQ/DSC-0080-Poprawione-Szum.jpg",
//     "https://i.ibb.co/2WXDVMN/DSC-0954-Poprawione-Szum.jpg",
//     "https://i.ibb.co/sqtSQ1T/DSC-5030-Poprawione-Szum.jpg",
//     "https://i.ibb.co/x31C6QP/DSC-5389-Poprawione-Szum.jpg",
//     "https://i.ibb.co/277qRHQ/DSC-7120-Poprawione-Szum.jpg",
//     "https://i.ibb.co/r5m9vb2/DSC-9603-Poprawione-Szum.jpg"
// ]
const photos = [
    "https://i.ibb.co/rwShMRS/DSC-0080-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/nrz2WgB/DSC-0954-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/cxzNfSv/DSC-5030-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/71zHcVy/DSC-5389-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/Sdk9XQm/DSC-7120-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/D9W7G5D/DSC-9603-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/BwwHS6d/mp94-53.jpg"
]
export const Portret = () => {
    return (
        <PhotoGallery photos={photos} />
    )
}
